import { createTheme } from '@mui/material';

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#57CC99'
        },
        secondary: {
            main: '#57CC99' // to do
        },
        error: {
            main: '#FF0000'
        }
    }
})