import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage } from '../pages/login';

export const AppRouter = () => {
  return (
    <Routes>
        <Route path="/login" element={ <LoginPage /> } />

        {/* to do */}
        <Route path="/" element={ <h3>Checkmapp Host</h3> } />

        <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  )
}